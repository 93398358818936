'use client'
import { useState, useEffect, Fragment } from 'react'
import { useSession, signOut } from "next-auth/react"
import { PrismicNextLink, PrismicNextImage } from '@prismicio/next'
import SearchIcon from '@/assets/icons/search.svg'
import Cart from '@/assets/icons/cart-empty.svg'
import CartFull from '@/assets/icons/cart-full.svg'
import Account from '@/assets/icons/account.svg'
import RightArrow from '@/assets/icons/right-arrow.svg'
import CloseIcon from '@/assets/icons/close.svg'
import MegaMenu from '@/slices/MegaMenu'
import _ from 'lodash'


interface MegaMenusProps {
    showBanner?: any | null
    isPanelVisible?: any | null
    alertHeight?: any | null
    megaMenus?: any | null
    menuOrder?: any | null
    theme?: any | null
    subMenu?: any | null
    cin7Menu?: any | null
    defImage?: any | null
    collections?: any | null
    lang?: any | null
    productType?: any | null
    menuOpen?: any | null
    setMenuOpen?: any | null
    megaOpen?: any | null
    setMegaOpen?: any | null
    uid?: any | null
    setAccount?: any | null
    account?: any | null
    showCart?: any | null
    setShowCart?: any | null
    countryMenu?: any | null
    setCountryMenu?: any | null
    setShowSearch?: any | null
    cartTotal?: any | null
}

const MegaMenus:React.FC<MegaMenusProps> = ({ isPanelVisible, showBanner, alertHeight, megaMenus, menuOrder, theme, subMenu, cin7Menu, defImage, lang, productType, menuOpen, setMenuOpen, megaOpen, setMegaOpen, uid, account, setAccount, showCart, setShowCart, countryMenu, setCountryMenu, setShowSearch, cartTotal}) => {
    const menuPrimary = [] as any

    menuOrder?.forEach((order: any) => {
        if(order.standard_link_text !== "") {
            menuPrimary.push({menu_data: null, link_data: { text: order.standard_link_text, link: order.standard_link }});
        }
        megaMenus?.forEach((menu: any) => {
            if(menu.id === order.mega_menu.id) {
                menuPrimary.push({menu_data: menu.data, link_data: null});
            }
        })
    });

    const { data: session, status } = useSession()

    const user = session?.user as any;

    const [isTouch, setIsTouch] = useState(false);

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    const accountShow =() => {
        document.body.style.overflow="hidden";
        setAccount(true)
    }

    const showCartMenu = ()=>{
        setShowCart(!showCart); 
        document.body.style.overflow="hidden"
    }

    const handleTouchStart = () => {
        setIsTouch(true);
    };
    
    const handleMenuItemClick = (e: any, index: number, link: any) => {
        
        let linkDestination = link?.uid ? link.uid : link?.url?.replace("https://www.georgestreetlinen.com/", "")

        e.preventDefault();
        e.stopPropagation(); // Prevent the click event from propagating to the overlay
    
        // console.log(link)

        switch(link?.type) {
            case 'page':
                linkDestination = "/"+linkDestination
            break;
            case 'main_sections':
                linkDestination = "/"+linkDestination
            break;
            case 'collection':
                linkDestination = "/collections/"+linkDestination
            break;
            default: 

        }

        // console.log(link, linkDestination)

        if(linkDestination === "/about") {
            linkDestination = '/about-us'
        }

        if (isTouch) {
            if((link?.type === "page" || link?.type === "collection") && linkDestination !=='about') {
                window?.open(`/${lang}/${linkDestination}`, "_self")
            } else {
                setMegaOpen(index);
                setMenuOpen(true);
            }
            return;
        } 
       
        document.body.style.overflow = "hidden";
        window?.open(`/${lang}/${linkDestination}`, "_self")
        
    };

    const topStyle = showBanner && isPanelVisible
    ? { top: `${alertHeight + 22}px`, mdTop: '70px' }
    : { top: '23px' };

    const menuStyle = showBanner && isPanelVisible
    ? { top: `${alertHeight + 59}px`, mdTop: '70px' }
    : { top: '59px' };


    megaMenus.map((megaMenus: any, index: any)=>{
        const subtypes = _.find(cin7Menu, item => 
            typeof item.producttype === "string" && 
            item.producttype.toLowerCase() === megaMenus.uid.toLowerCase()
        );
        
        if (subtypes) {
            // Preserve original values for restoring casing later
            const originalSubtypes = subtypes.productsubtypes.slice();
            
            const orderedSubtypes = megaMenus.data.productsubtype_order
                .map((subtype: any) =>
                    typeof subtype.productsubtype === "string" ? subtype.productsubtype.toLowerCase() : subtype
                );
            
            const existingSubtypes = subtypes.productsubtypes
                .map((subtype: any) => 
                    typeof subtype === "string" ? subtype.toLowerCase() : subtype
                );

            const sortedSubtypes = [
                ...orderedSubtypes.filter((subtype: any) => existingSubtypes.includes(subtype)), // Keep ordered ones first
                ...existingSubtypes.filter((subtype: any) => !orderedSubtypes.includes(subtype)) // Append missing ones
            ];

            subtypes.productsubtypes = sortedSubtypes.map((subtype: any) =>
                originalSubtypes.find((original: any) => 
                    typeof original === "string" && original.toLowerCase() === subtype
                ) || subtype
            );

            // ProjectNames

            const originalProjectNames = subtypes.projectname.slice();
        
            const orderedProjectNames = megaMenus.data.projectname_order
                .map((name: any) =>
                    typeof name === "string" ? name.toLowerCase() : name
                );

            // Convert orderedProjectNames from objects to lowercase strings
            const orderedProjectNamesList = orderedProjectNames
            .map((obj: any) => typeof obj.projectname === "string" ? obj.projectname.toLowerCase() : "")
            .filter((name: any) => name); // Remove empty names


            const existingProjectNames = subtypes.projectname
                .map((name: any) =>
                    typeof name === "string" ? name.toLowerCase() : name
                );

            // Sort existing project names based on ordered list
            const sortedProjectNames = existingProjectNames.slice().sort((a: any, b: any) => {
                const indexA = orderedProjectNamesList.indexOf(a);
                const indexB = orderedProjectNamesList.indexOf(b);

                // If not found in orderedProjectNames, keep original order
                return (indexA === -1 ? existingProjectNames.length : indexA) - 
                    (indexB === -1 ? existingProjectNames.length : indexB);
            });

            subtypes.projectname = sortedProjectNames.map((name: any) =>
                originalProjectNames.find((original: any) => 
                    typeof original === "string" && original.toLowerCase() === name
                ) || name
            );
        
            cin7Menu = cin7Menu.map((item: any) => 
                item === subtypes ? { ...item, productsubtypes: subtypes.productsubtypes, projectname: subtypes.projectname} : item
            );
            // console.log("After update:", JSON.stringify(cin7Menu, null, 2));
        }
        
        // console.log(cin7Menu)
    })

    return (
        <>
            {menuOpen === true ? 
            <>
            <div className={"text-warmcharcoal fixed z-[2] left-4 right-auto md:right-8 md:left-auto lg:right-[54px] cursor-pointer hover:opacity-60 text-[14px] font-norma lg:hidden -mt-[10px]"} 
                    onClick={()=>{setMenuOpen(false); document.body.style.overflow="visible"}} 
                    style={topStyle}>
                <CloseIcon className="stroke-warmcharcoal inline-block ml-2"/>
            </div>
            <div className={`${megaOpen === -1 ? "":"bottom-0"} w-screen z-[200] fixed bg-warmgrey/60 left-0 font-norma overflow-y-scroll lg:overflow-hidden text-[14px] border-none -mt-2 md:mt-0`} style={menuStyle}>
                <div className="w-full bg-offwhite pt-2 md:pt-0 " >
                    <ul className="p-5 pt-0 pb-3 lg:pl-[54px] w-full relative z-10 lg:-mt-[20px] ">
                        {menuPrimary.map((primaryItem: any, index: any)=>{
                            if(primaryItem.menu_data?.section_name) return (
                                <li className={(primaryItem.link_data?.text.toLowerCase() === uid || primaryItem.link_data?.text.toLowerCase() === productType? " underline-offset-4":"")+" flex justify-between text-[14px] leading-[18px] font-medium w-full md:w-auto md:inline-block md:mr-[20px] lg:mr-[50px] cursor-pointer py-4 md:pb-0 hover:underline hover:underline-offset-4 border-b-[1px] border-b-warmcharcoal/20 md:border-none"+ (index === megaOpen ? "underline underline-offset-4" : "")} key={index} 
                                    onClick={(e:any) => handleMenuItemClick(e, index, primaryItem.link_data?.link)}
                                    onMouseEnter={() => {
                                        if (!isTouch && index !== megaOpen && primaryItem.link_data?.link?.type !== "page") {
                                            setMegaOpen(index)
                                        }
                                    }}
                                    >
                                        <span className="block">{primaryItem.menu_data.section_name}</span> 
                                        <RightArrow className="stroke-warmcharcoal rotate-180 md:hidden inline-block"/>
                                </li>
                            ) 
                            if(primaryItem.link_data?.text) {
                                return (
                                    <li className={(primaryItem.link_data?.text.toLowerCase() === uid || primaryItem.link_data?.text.toLowerCase() === productType ? " underline-offset-4":"")+" flex justify-between text-[14px] leading-[18px] font-medium w-full md:w-auto md:inline-block md:mr-[10px] lg:mr-[28px] cursor-pointer py-4 md:pb-0 hover:underline hover:underline-offset-4 border-b-[1px] border-b-warmcharcoal/20 md:border-none"} key={index}>
                                        <a href={primaryItem.link_data.link?.uid ? "/"+lang+"/"+primaryItem.link_data.link.uid : primaryItem.link_data.link?.url} 
                                            onClick={(e:any) => handleMenuItemClick(e, index, primaryItem.link_data?.link)}
                                            onMouseEnter={() => {
                                            if (!isTouch && index !== megaOpen && primaryItem.link_data?.link?.type !== "page") {
                                                setMegaOpen(index)
                                            }
                                        }}
                                            className={`md:inline flex justify-between w-full ${productType === primaryItem.link_data.text?.toLowerCase() ? "underline underline-offset-4" :""}`}>
                                            <span>{primaryItem.link_data.text}</span> 
                                            <RightArrow className="stroke-warmcharcoal rotate-180 md:hidden inline-block"/>
                                        </a>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    {menuPrimary.map((item: any, index: number)=>{
                        if(index === megaOpen) {
                            const hasCin7Slice = megaMenus?.some(
                                (mega: any) =>
                                    mega.data?.generate_links_from_cin7 === true &&
                                    mega.uid?.toLowerCase() === item.link_data?.text?.toLowerCase()
                            );
                            return (
                                <div className={`absolute left-0 top-[20px] bg-offwhite md:top-[30px] right-0 lg:left-0 z-[60] min-h-screen md:min-h-0 border-t-[1px] border-warmcharcoal font-norma md:pb-0 ${megaOpen !== null ? 'block' : 'hidden'}`} key={index} >    
                                    <div className="md:px-5 lg:px-[54px] w-full" key={index} onMouseLeave={() => setMegaOpen(-1)}>
                                            {hasCin7Slice ?
                                                <div className="lg:grid lg:grid-cols-12 pb-4">
                                                    <div className="lg:col-span-4">
                                                        {cin7Menu?.map((c7item: any, c7index: number)=>{
                                                            if(c7item.producttype === item.link_data.text){
                                                            return (
                                                                <>
                                                                    <h3 key={c7index} className="md:hidden capitalize text-[14px] font-medium p-4 border-b-[1px] border-b-warmcharcoal cursor-pointer" onClick={(e:any) => handleMenuItemClick(e, -1, item.link_data.link)}><RightArrow className="stroke-warmcharcoal inline-block mr-2"/> {item.link_data.text}</h3>
                                                                    <div className="" >
                                                                    <h4 className="font-medium my-4 mb-3 px-4 pb-2 md:px-0 border-b-[1px] border-b-warmcharcoal/50">Products</h4>
                                                                        {c7item.productsubtypes?.map((sub: any, mindex: number)=>{
                                                                            return (
                                                                                <div className="col-span-12 md:col-span-3 lg:col-span-2 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0" key={mindex}>
                                                                                    <p className={"inline-block pb-3 md:pb-0 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 "} key={mindex}>
                                                                                        <a className={sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---") === uid ? " underline underline-offset-4 font-medium" : ""} href={"/"+lang+"/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-")+"/"+sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---")}>{sub.replace("&", "+")}</a>
                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        <div className="col-span-12 md:col-span-3 lg:col-span-2 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0 pb-6">
                                                                            <p className={"inline-block pb-3 md:pb-0 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 "}>
                                                                                <a href={"/"+lang+"/shop/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-")} className="font-medium">Shop all</a>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        })}
                                                    </div>
                                                    <div className="lg:col-span-8">
                                                        {cin7Menu?.map((c7item: any, c7index: number)=>{
                                                            if(c7item.producttype === item.link_data.text){
                                                            return (
                                                                <div className="col-span-12 md:col-span-6" key={c7index}>
                                                                    <div className="pb-4">
                                                                        <h4 className="font-medium my-4 mb-3 px-4 md:px-0 pb-2 border-b-[1px] border-b-warmcharcoal/50">Fabrics</h4>
                                                                        <div className="lg:grid lg:grid-cols-12 gap-x-8">
                                                                            {c7item.projectname?.map((sub: any, mindex: number)=>{
                                                                                if(sub?.length > 0) {
                                                                                return (
                                                                                    <div className="col-span-12 md:col-span-6 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0" key={mindex}>
                                                                                        <p className={"inline-block pb-3 md:pb-0 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 "} key={mindex}>
                                                                                            <a className={sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---") === uid ? " underline underline-offset-4 font-medium" : ""} href={"/"+lang+"/fabrics/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-")+"/"+sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---")}>{sub.replace("&", "+")}</a>
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                {megaMenus?.map((mega: any, megaIndex: number) => {
                                                    if (mega.uid.replace(/-/g, " ")?.toLowerCase() === menuPrimary[megaOpen]?.link_data?.text?.toLowerCase()) {
                                                        
                                                        return (
                                                            <Fragment key={megaIndex}>
                                                            <h3 className="md:hidden capitalize text-[14px] font-medium m-4 border-b-[1px] border-b-warmcharcoal -mx-4 pb-4 px-8 cursor-pointer" onClick={(e:any) => handleMenuItemClick(e, -1, item.link_data.link)}><RightArrow className="stroke-warmcharcoal inline-block mr-2"/> {mega.uid}</h3>
                                                            <div className="grid grid-cols-12 w-full pb-4 px-4 " key={megaIndex} >
                                                                {mega.data?.slices?.length > 0 
                                                                    ? mega.data.slices.map((slice: any, sindex: number) => (
                                                                        <Fragment key={sindex}>
                                                                            {slice.items.map((item: any, itemIndex: number) => (
                                                                                <div className="col-span-12 md:col-span-3 lg:col-span-2 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0" key={itemIndex}>
                                                                                    <p
                                                                                        className={`capitalize inline-block py-2 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4`}
                                                                                    >
                                                                                        <a
                                                                                            className={`${
                                                                                                item.collection_page?.uid === uid
                                                                                                    ? ' underline underline-offset-4 font-medium '
                                                                                                    : ''
                                                                                            }`} href={`/${lang}/collections/${slice.primary.collection_section?.uid}/${item.collection_page?.uid}`} onClick={()=>{setMegaOpen(index)}}
                                                                                        >
                                                                                            {item.collection_page?.uid
                                                                                                ?.replace(/---/g, '-+-')
                                                                                                .replace(/-/g, ' ')}
                                                                                        </a>
                                                                                    </p>
                                                                                </div>
                                                                            ))}
                                                                        </Fragment>
                                                                    ))
                                                                    : mega.data.links?.map((page: any, pageIndex: number) => {
                                                                        // console.log('last: ', page.link)
                                                                        return (
                                                                        <div className="col-span-12 border-b-[1px] border-b-warmcharcoal/50 lg:border-none" key={pageIndex} >
                                                                            <p
                                                                                className={`capitalize inline-block py-2 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 ${
                                                                                    page.link_text?.trim().toLowerCase().replace(/ /g, '-').replace(/-&-/, '-') ===
                                                                                    uid
                                                                                        ? ' underline underline-offset-4 font-medium '
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                <a onClick={(e:any) => handleMenuItemClick(e, -1, page.link)}>{page.link_text}</a>
                                                                            </p>
                                                                        </div>
                                                                    )})}
                                                            </div>
                                                            </Fragment>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>}
                                        </div>
                                    </div>
                            )
                        }
                        return null
                    })}
                </div>
            </div>
            </>
        :<></>}
        </>
    )
}

export default MegaMenus
