'use client'

import React, { useRef, useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import SearchIcon from '@/assets/icons/search-icon.svg'
const libraries: ("places")[] = ["places"];

interface PlacesSearchProps {
  input?: any | null
  lang?: any | null
  onAddressSelect?: (address: { formatted_address: string, address_components: any[] }) => void
}

const PlacesSearch: React.FC<PlacesSearchProps> = ({ input, lang, onAddressSelect }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDazhRoeHMUSBj6ypPWGQjDcDpu6P-ZdXk",
    libraries,
    id: "google-map-script"
  });

  const inputAddress = input.data
  const inputRef = useRef<HTMLInputElement>(null);
  const [address, setAddress] = useState(inputAddress.formatted);

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const options = {
        componentRestrictions: lang === "global" ? null : { country: lang },
        fields: ["formatted_address", "geometry.location", "address_components"],
      } as any;

      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place?.geometry?.location) {
          const formattedAddress = place.formatted_address || "";
          const addressComponents = place.address_components || [];
          setAddress(formattedAddress);
          if (onAddressSelect) {
            onAddressSelect({ formatted_address: formattedAddress, address_components: addressComponents });
          }
        }
      });
    }
  }, [isLoaded, lang, input, onAddressSelect]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="relative xxl:ml-4 mt-4">
      <SearchIcon className="absolute stroke-warmcharcoal w-[20px] h-[20px] left-4 top-3"/>
      <input
        ref={inputRef}
        id="google-map-script"
        type="text"
        placeholder="Lookup address"
        defaultValue={address ? address : inputAddress.formatted_address}
        autoComplete="off"
        className="h-[42px] w-full pl-12 px-4 font-norma  text-[16px] md:text-[14px] placeholder:text-warmcharcoal focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] bg-white"
      />
    </div>
  );
};

export default PlacesSearch;

